import { Title } from '@solidjs/meta';

export default function InteractivePage() {
	return (
		<>
			<Title>Interactive | Style Guide | Troon</Title>

			<div class="mb-8 grid grid-cols-12 gap-4" />
		</>
	);
}
